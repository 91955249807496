<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="displayValue"
        :rules="rules"
        :placeholder="placeholder"
        :persistent-placeholder="persistentPlaceholder"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        clearable
        color="black"
        background-color="white"
        @click:clear="$emit('clear')"
      >
        <template v-slot:label>
          {{ label }}
          <span v-if="size(rules) > 0" class="red--text">*</span>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="dateValue"
      :active-picker.sync="activePicker"
      :max="
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      "
      min="1950-01-01"
      @change="save"
      color="#f7941e"
    />
  </v-menu>
</template>

<script>
import { size } from 'lodash'
import moment from 'moment'

export default {
  computed: {
    dateValue: {
      get() {
        return this.value
      },
      set() {
        return this.value
      },
    },
    displayValue: {
      get() {
        const { value, format } = this
        const result = value !== null ? moment(value).format(format) : value
        return result
      },
      set() {
        return this.value
      },
    },
  },
  data: () => ({
    activePicker: null,
    menu: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
    size,
    save(date) {
      this.$refs.menu.save(date)
      this.$emit('input', date)
    },
  },
  props: {
    label: {
      type: String,
      default: 'Date Input',
    },
    placeholder: {
      type: String,
      default: 'Date Input',
    },
    value: {
      type: [Array, String],
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: undefined,
    },
  },
}
</script>
