import { mock } from "../../devConfig";
import jwtDecode from "jwt-decode";
import errorCode from "../utils/errorCode";
// import store from '../store'
import * as Sentry from "@sentry/vue";

const API_URL = process.env.VUE_APP_API_URL;
// const urlParams = new URLSearchParams(window.location.search);

/* eslint-disable no-unused-vars */
let to = null,
  from = null,
  next = null;
export default async function Auth(_to, _from, _next) {
  to = _to;
  from = _from;
  next = _next;

  // already token
  if (window.$apiToken) {
    return true;
  }

  // check and set mock
  if (process.env.NODE_ENV !== "production") {
    if (mock.apiToken) {
      console.log("-- use mock apiToken");
      console.log("mock.apiToken:", mock.apiToken);
      window.$apiToken = mock.apiToken;
      console.log("-- set api token");

      window.$tokenData = checkTokenData(window.$apiToken);
      console.log("-- check, set token data");

      // await checkConsent()
      // store.set('consent/check', true)
      return true;
    }
  }

  if (!window.$authToken) {
    const app_access_token = await getCodeToken();
    window.$authToken = await getAuthToken(app_access_token);
    console.log("-- set authToken");
  }

  try {
    const res = await postAuth({
      channel: "line",
      credential: { accessToken: window.$liffData.accessToken },
    });
    window.$apiToken = res;
  } catch (err) {
    // to login page
    return next("/login");
  }

  console.log("-- set api token");

  window.$tokenData = checkTokenData(window.$apiToken);
  console.log("-- set token data");

  // store.set('consent/check', true)
  return true;
}

export async function getMktConsent() {
  try {
    const res = await fetch(`${API_URL}/api/mktconsents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$authToken}`,
      },
    });

    if (res.status !== 200) {
      throw errorCode(102);
    }

    const response = await res.json();

    return response;
  } catch (error) {
    console.error(error);
    // next({ name: `error`, params: error })
  }
  return true;
}

export async function acceptMktConsent(isAccept = false) {
  try {
    if (to.meta.authUser === false) {
      return true;
    }

    const reqBody = {
      isAccept,
    };

    const res = await fetch(`${API_URL}/api/mktconsents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$apiToken}`,
      },
      body: JSON.stringify(reqBody),
    });

    if (res.status !== 200) {
      throw errorCode(102);
    }
    return await res.json();
  } catch (error) {
    console.error(error);
    return error;
  }
  // return true
}

export async function checkMktConsent() {
  try {
    if (to.meta.authUser === false) {
      return true;
    }
    if (window.$tokenData.u.id != null) {
      const res = await fetch(`${API_URL}/api/mktconsents/waiting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.$apiToken}`,
        },
      });

      if (res.status !== 200) {
        throw errorCode(102);
      }

      const response = await res.json();

      return response;
    }
  } catch (error) {
    console.error(error);
  }
  return true;
}

export async function getConsent() {
  try {
    const res = await fetch(`${API_URL}/api/consents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$authToken}`,
      },
    });

    if (res.status !== 200) {
      throw errorCode(102);
    }

    const response = await res.json();

    return response;
  } catch (error) {
    console.error(error);
    // next({ name: `error`, params: error })
  }
  return true;
}

export async function acceptConsent(submit_list) {
  try {
    if (to.meta.authUser === false) {
      return true;
    }

    const reqBody = {
      data: submit_list,
    };

    const res = await fetch(`${API_URL}/api/consents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$apiToken}`,
      },
      body: JSON.stringify(reqBody),
    });

    if (res.status !== 200) {
      throw errorCode(102);
    }

    if (res.body.length > 0) {
      return res.body;
    }
  } catch (error) {
    console.error(error);
  }
  return true;
}

export async function checkConsent() {
  try {
    if (to.meta.authUser === false) {
      return true;
    }
    if (window.$tokenData.u.id != null) {
      const res = await fetch(
        `${API_URL}/api/users/${window.$tokenData.u.id}/consents/waiting`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.$apiToken}`,
          },
        }
      );

      if (res.status !== 200) {
        throw errorCode(102);
      }

      const response = await res.json();

      return response;
    }
  } catch (error) {
    console.error(error);
  }
  return true;
}

export async function getCodeToken() {
  // console.log('getCodeToken...')
  // const hashToken = getHashValue('access_token')

  // if (hashToken) {
  //   localStorage.setItem('$APP_ACCESS_TOKEN', hashToken)
  //   return hashToken
  // }

  // const app_access_token = localStorage.getItem('$APP_ACCESS_TOKEN')

  // if (app_access_token) {
  //   localStorage.removeItem('$APP_ACCESS_TOKEN')
  //   return app_access_token
  // }

  // window.location = `${API_URL}/api/auth?application=${
  //   process.env.VUE_APP_APP_SLUG
  // }&redirectUrl=${encodeURIComponent(window.location.href.split('#')[0])}`

  // console.log('redirect auth...')
  // await new Promise((resolve) => setTimeout(resolve, 15000))

  // create promise for wait redirect and get token on iframe
  return await new Promise((resolve, reject) => {
    const iframe_redirect_auth = document.createElement("iframe");
    iframe_redirect_auth.id = "iframe_redirect_auth";

    window.addEventListener("message", (e) => {
      if (e.data?.target !== "$iframe_redirect_auth") {
        return;
      }

      console.log("iframe_redirect_auth: message", e.data);

      iframe_redirect_auth.remove();

      if (e.data?.access_token) {
        return resolve(e.data?.access_token);
      }

      if (e.data?.error) {
        return reject(errorCode(109));
      }
    });

    iframe_redirect_auth.onload = () => {
      console.log("== iframe_redirect_auth.onload");
      setTimeout(() => {
        return reject(errorCode(110));
      }, 2000);
    };

    iframe_redirect_auth.src = `${API_URL}/api/auth?application=${
      process.env.VUE_APP_APP_SLUG
    }&redirectUrl=${encodeURIComponent(
      window.location.origin + "/getAppCode.html"
    )}`;

    document.body.appendChild(iframe_redirect_auth);

    console.log("-- inject redirect token auth...");
  }).catch((err) => {
    console.error(err);
    if (err.code) throw err;
    throw errorCode(111);
  });
}

function getHashValue(key) {
  var matches = location.hash.match(new RegExp("#" + key + "=([^&]*)"));
  return matches ? matches[1] : null;
}

export async function getAuthToken(code) {
  // prevent for some flow case not remove app_access_token
  localStorage.removeItem("$APP_ACCESS_TOKEN");

  const res = await fetch(
    `${API_URL}/api/access_token?application=${process.env.VUE_APP_APP_SLUG}&code=${code}`,
    {
      method: "GET",
    }
  );

  if (res.status !== 200) {
    throw errorCode(102);
  }

  const { access_token } = await res.json();

  if (!access_token) {
    throw errorCode(103);
  }

  return access_token;
}

export async function postAuth(
  { channel = "", credential = {}, link },
  pass = true
) {
  const reqBody = { channel, credential, ...(link && { link }) };

  const res = await fetch(`${API_URL}/api/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.$authToken}`,
    },
    body: JSON.stringify(reqBody),
  });
  if (!pass) return await res.json();

  if (res.status === 401) {
    throw errorCode(108);
  }

  if (res.status === 200) {
    const { access_token } = await res.json();
    if (!access_token) {
      throw errorCode(104);
    }

    return access_token;
  }

  throw errorCode(107);
}

// check user in jwt and set tracking user service
export function checkTokenData(apiToken) {
  const decode = jwtDecode(apiToken);

  if (decode.u.id === 0) {
    throw errorCode(105);
  }

  // if (decode.s.length === 0) {
  //     throw errorCode(106)
  // }

  setUserSentry(decode.u.id);
  return decode;
}

function setUserSentry(uid) {
  try {
    // set userId tracking
    uid && Sentry.setUser({ id: uid });
  } catch (err) {
    console.error(err);
  }
}

export async function getSubDistrict({ listType = null, query = null }) {
  const res = await fetch(
    `${API_URL}/api/sub-districts?listType=${listType}${
      query !== null ? `&query=${query}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$authToken}`,
      },
    }
  );

  if (res.status === 401) {
    throw errorCode(108);
  }

  if (res.status === 200) {
    const { data } = await res.json();
    return data;
  }

  throw errorCode(107);
}

export async function getDistrict({
  listType = null,
  provinceId = null,
  query = null,
}) {
  const res = await fetch(
    `${API_URL}/api/districts?listType=${listType}${
      provinceId !== null ? `&provinceId=${provinceId}` : ""
    }${query !== null ? `&query=${query}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$authToken}`,
      },
    }
  );

  if (res.status === 401) {
    throw errorCode(108);
  }

  if (res.status === 200) {
    const { data } = await res.json();
    return data;
  }

  throw errorCode(107);
}

export async function getProvince({ listType = null, query = null }) {
  const res = await fetch(
    `${API_URL}/api/provinces?listType=${listType}${
      query !== null ? `&query=${query}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$authToken}`,
      },
    }
  );

  if (res.status === 401) {
    throw errorCode(108);
  }

  if (res.status === 200) {
    const { data } = await res.json();
    return data;
  }

  throw errorCode(107);
}

export async function getPostcodes({
  listType = null,
  provinceId = null,
  districtId = null,
  query = null,
}) {
  const res = await fetch(
    `${API_URL}/api/postcodes?listType=${listType}${
      provinceId !== null ? `&provinceId=${provinceId}` : ""
    }${districtId !== null ? `&districtId=${districtId}` : ""}${
      query !== null ? `&query=${query}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.$authToken}`,
      },
    }
  );

  if (res.status === 401) {
    throw errorCode(108);
  }

  if (res.status === 200) {
    const { data } = await res.json();
    return data;
  }

  throw errorCode(107);
}

export async function postRegister(reqBody) {
  const res = await fetch(`${API_URL}/api/users/customers/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.$authToken}`,
    },
    body: JSON.stringify(reqBody),
  });
  if (res.status === 422) {
    throw await res.json();
  }

  if (res.status === 401) {
    throw errorCode(108);
  }

  if (res.status === 201) {
    return await res.json();
  }

  throw errorCode(107);
}
