const API_URL = process.env.VUE_APP_API_URL

function API_HEADERS() {
  let headers = {}
  if (window?.$apiToken) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.$apiToken}`,
    }
  }
  return headers
}

export async function REQUEST_GET(URL, data = {}) {
  const headers = API_HEADERS()
  const res = await fetch(
    `${API_URL}${URL}${data.page ? `?page=${data.page}` : ''}`,
    {
      method: 'GET',
      headers,
    }
  )
  return res
}

export async function REQUEST_POST(URL, data) {
  const headers = API_HEADERS()
  const res = await fetch(`${API_URL}${URL}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })
  return res
}
