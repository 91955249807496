import { REQUEST_GET } from './API_Request'

export const state = {
  userProfile: {},
}

export const mutations = {
  async GET_USER_PROFILE(state) {
    try {
      const res = await REQUEST_GET('/api/me')
      const body = await res.json()
      state.userProfile = body
      return state
    } catch (e) {
      return e
    }
  },
}

export const actions = {
  async getUserProfile() {
    return mutations.GET_USER_PROFILE(state)
  },
}

export const getters = {
  // Add Logic Before Computed
  userProfile(state) {
    // eslint-disable-line
    return state.userProfile
  },
}

export const users = {
  state,
  mutations,
  actions,
  getters,
}
