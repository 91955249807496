import { mapActions, mapGetters } from 'vuex'

//Global computed and methods
export default {
  //Store getters
  computed: mapGetters(['checkMkt', 'points', 'userProfile']),
  //Store actions
  methods: {
    ...mapActions(['toggleCheckMkt', 'getHistoryPoints', 'getUserProfile']),
  },
}
